<template>
  <div class='inline-block items-stretch px-8'>
    <div v-for='[key, value] in Object.entries(accounts)' v-bind:key='key'>
      <tr>
        <th class='w-24' :style='isType'>{{accountType}}</th>
        <th class='w-32' :style='isType'>{{value[0].entity_name}}</th>
        <th class='w-32' :style='isType'>{{key}}</th>
        <th class='w-32' :style='isType'>{{value[0].contact_phone_number}}</th>
      </tr>
      <tr>
        <td style='border-color:black;'>배정수량</td>
        <td style='border-color:black;'>청약금액</td>
        <td style='border-color:black;'>청약수수료</td>
        <td style='border-color:black;'>납입금액</td>
      </tr>
      <tr>
        <td style='border-color:black;'>{{numberStyle(totalQuantity(value))}}</td>
        <td style='border-color:black;'>{{numberStyle(totalAllocatedAmount(value))}}</td>
        <td style='border-color:black;'>{{numberStyle(totalFee(value))}}</td>
        <td style='border-color:black;'>{{numberStyle(totalPaymentAmount(value))}}</td>
      </tr>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IpoResultAccountsRow',
  props: [
    'accountType',
    'accounts',
  ],
  computed: {
    isType () {
      return this.accountType === '집합' ? 'background-color:#ffbd37; border-color:black' : this.accountType === '벤처' ? 'background-color:#75ec8b; border-color:black' : 'background-color:#b0c4de; border-color:black'
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    totalQuantity (value) {
      return value.reduce((sum, result) => sum + result.total_allocated_quantity, 0)
    },
    totalAllocatedAmount (value) {
      return value.reduce((sum, result) => sum + result.allocated_amount, 0)
    },
    totalFee (value) {
      return value.reduce((sum, result) => sum + result.subs_fee_amount, 0)
    },
    totalPaymentAmount (value) {
      return value.reduce((sum, result) => sum + result.payment_amount, 0)
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply top-0 py-2 border whitespace-no-wrap;
  }
  td {
    @apply text-right top-0 py-1 pl-2 pr-6 border whitespace-no-wrap;
  }
</style>
